import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">

<path d="M2685 4174 c-84 -56 -135 -118 -135 -164 0 -31 35 -60 74 -60 34 0
46 -13 46 -49 0 -36 -82 -140 -224 -284 -121 -123 -151 -160 -181 -221 -34
-71 -56 -153 -71 -264 -10 -77 -27 -51 -32 50 -4 70 0 95 22 161 36 107 34
121 -19 175 -49 50 -51 50 -31 2 21 -50 3 -102 -71 -214 -89 -135 -101 -165
-107 -271 -2 -53 -1 -80 4 -65 l8 25 1 -25 c1 -26 -11 -52 -23 -51 -3 1 -6 -1
-6 -4 0 -3 0 -8 0 -11 0 -4 7 -1 15 6 13 11 18 7 32 -29 9 -22 23 -41 31 -41
7 0 27 -14 43 -31 l29 -31 1 33 c1 19 0 39 -1 44 -17 67 7 80 48 26 30 -40 79
-85 128 -118 31 -22 33 -22 39 -4 3 10 1 29 -4 42 -11 32 11 206 37 280 21 59
36 89 65 128 l17 24 0 -44 c1 -113 46 -319 83 -374 30 -45 86 -94 131 -116 56
-27 149 -41 214 -33 l55 7 -23 -33 c-30 -42 -82 -63 -142 -58 -26 3 -46 2 -43
-2 2 -3 29 -19 59 -34 99 -50 189 -27 257 67 58 80 29 211 -80 356 -70 93 -47
128 77 117 l63 -5 -28 23 c-70 58 -126 69 -181 36 -50 -31 -66 -79 -55 -165
15 -104 -9 -149 -67 -130 -25 8 -27 68 -6 120 l15 35 -27 -17 c-15 -9 -35 -26
-44 -37 -17 -21 -18 -20 -17 14 0 44 21 105 46 132 10 12 49 41 87 65 142 92
202 172 173 231 -8 16 -44 42 -102 71 -102 52 -110 66 -70 121 28 37 23 46
-17 31 -72 -27 -132 -117 -108 -161 8 -16 21 -20 58 -20 60 0 92 -21 92 -60 0
-44 -37 -64 -104 -57 -89 9 -111 33 -107 116 3 64 56 205 101 271 28 42 80
177 80 209 0 17 -9 40 -19 50 -18 18 -79 41 -108 41 -19 0 -26 42 -13 75 6 16
27 51 46 77 18 26 33 48 31 48 -1 0 -20 -12 -42 -26z m-700 -1078 c0 -15 -4
-21 -9 -16 -5 5 -6 16 -3 24 9 22 12 20 12 -8z m2 -133 c-3 -10 -5 -4 -5 12 0
17 2 24 5 18 2 -7 2 -21 0 -30z m310 5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-294 -53 c-7 -21 -13 -19 -13 6 0 11 4 18 10 14 5 -3 7 -12
3 -20z m50 -15 c-3 -12 -8 -19 -11 -16 -5 6 5 36 12 36 2 0 2 -9 -1 -20z m181
-86 c5 -14 4 -15 -9 -4 -17 14 -19 20 -6 20 5 0 12 -7 15 -16z m46 -20 c0 -8
-4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z"/>
<path d="M1921 2964 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1934 2877 c3 -10 6 -29 6 -41 0 -12 7 -31 16 -42 15 -18 15 -18 5 3
-6 12 -11 32 -11 45 0 13 -5 30 -11 38 -9 12 -10 12 -5 -3z"/>
<path d="M1972 2840 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1981 2738 c9 -16 29 -17 29 -2 0 3 -5 2 -12 -2 -7 -5 -9 -2 -6 8 4
8 0 15 -7 15 -10 0 -11 -5 -4 -19z"/>
<path d="M2024 2746 c3 -23 26 -44 44 -42 21 4 29 36 10 47 -21 14 -35 10 -15
-5 22 -17 22 -26 -3 -26 -13 0 -20 7 -20 20 0 11 -4 20 -9 20 -5 0 -8 -6 -7
-14z"/>
<path d="M2101 2724 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2110 2697 c0 -5 5 -18 11 -29 8 -16 8 -22 -2 -28 -16 -10 -51 11
-45 27 3 8 -1 10 -11 6 -11 -4 -14 -2 -9 5 5 8 2 10 -9 6 -13 -5 -10 -12 17
-35 18 -15 39 -26 46 -24 6 2 12 -3 12 -11 0 -8 4 -13 9 -10 5 4 11 3 13 -2 3
-8 78 -56 78 -50 0 2 -14 17 -32 33 -18 17 -28 24 -23 16 5 -9 4 -12 -3 -7 -6
4 -9 13 -5 21 3 10 -1 15 -12 15 -9 0 -13 4 -10 10 3 5 -1 22 -9 37 -8 16 -15
24 -16 20z"/>
<path d="M2095 2660 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2190 2401 c-13 -25 -13 -34 0 -26 16 10 32 45 21 45 -6 0 -15 -9
-21 -19z"/>
<path d="M2380 2393 c0 -37 -10 -50 -41 -58 -21 -5 -27 -2 -32 14 -6 25 -25
28 -34 5 -4 -10 6 -26 26 -44 53 -49 62 -109 25 -167 -12 -19 -12 -22 1 -17
17 7 20 15 40 129 25 137 28 165 21 165 -3 0 -6 -12 -6 -27z m-20 -77 c0 -3
-4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"/>
<path d="M2735 2401 c-5 -16 -15 -21 -41 -21 -27 0 -34 -4 -34 -19 0 -11 -3
-26 -6 -35 -5 -13 1 -16 26 -16 28 0 31 -3 28 -27 -2 -23 -8 -29 -35 -31 -18
-2 -33 -9 -33 -15 0 -7 -3 -22 -6 -34 -5 -18 -2 -23 13 -23 10 0 24 5 31 12 9
9 12 2 13 -32 0 -34 5 -20 19 59 11 57 23 125 29 150 10 48 8 68 -4 32z m-20
-54 c0 -9 -4 -17 -10 -16 -5 1 -9 5 -7 10 1 5 -2 9 -8 9 -5 0 -10 5 -10 11 0
6 8 9 18 7 9 -2 17 -11 17 -21z"/>
<path d="M3186 2405 c-3 -9 -6 -25 -6 -35 0 -11 -8 -24 -17 -29 -10 -6 -13
-11 -6 -11 8 0 10 -12 6 -37 -3 -21 -2 -35 3 -30 4 4 13 40 20 80 12 71 12 93
0 62z"/>
<path d="M1888 2404 c-5 -4 -8 -32 -7 -63 1 -53 1 -54 10 -22 11 37 8 97 -3
85z"/>
<path d="M2075 2393 c3 -8 5 -27 6 -41 0 -24 1 -25 9 -7 14 33 11 62 -5 62
-10 0 -14 -6 -10 -14z"/>
<path d="M2567 2403 c-3 -4 -2 -26 2 -48 l7 -40 6 40 c5 38 -4 67 -15 48z"/>
<path d="M3282 2398 c-18 -22 -28 -88 -13 -88 15 0 33 47 29 79 -3 21 -5 22
-16 9z"/>
<path d="M3372 2340 c-7 -36 -16 -73 -19 -84 -3 -11 -1 -16 5 -12 5 3 12 23
16 45 3 22 13 41 22 45 14 5 15 1 11 -26 -17 -98 -18 -104 -7 -80 5 13 14 48
18 78 8 48 7 54 -9 54 -11 0 -19 9 -21 23 -2 12 -9 -6 -16 -43z"/>
<path d="M2858 2337 c-9 -28 -20 -72 -23 -96 -6 -33 -10 -40 -16 -29 -5 9 -5
38 1 68 12 64 12 70 0 48 -9 -14 -41 -151 -37 -154 1 -1 14 -4 28 -8 25 -5 27
-3 34 42 12 78 14 85 25 131 15 62 7 61 -12 -2z"/>
<path d="M2951 2353 c-8 -31 -41 -206 -41 -219 0 -2 6 -1 13 2 9 3 14 26 15
69 0 42 9 83 25 124 18 46 21 61 11 61 -7 0 -18 -17 -23 -37z"/>
<path d="M2825 2370 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3080 2360 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1674 2352 c-14 -10 -38 -71 -31 -79 4 -3 9 3 13 13 3 10 12 31 20
47 16 28 16 31 -2 19z"/>
<path d="M1962 2292 c-7 -37 -15 -79 -18 -92 l-6 -25 20 22 c23 26 56 128 48
148 -13 35 -32 13 -44 -53z"/>
<path d="M2446 2274 c-10 -47 -15 -88 -12 -91 8 -8 54 96 56 127 3 40 1 50
-13 50 -8 0 -19 -31 -31 -86z"/>
<path d="M1756 2311 c-10 -11 -24 -40 -31 -66 -12 -40 -12 -47 0 -52 23 -8 43
4 65 39 26 43 27 98 2 98 -11 0 -27 -9 -36 -19z m15 -84 c-11 -18 -21 -25 -21
-17 0 13 -1 13 -11 0 -8 -11 -9 -5 -4 25 4 24 15 47 28 57 21 17 22 16 25 -8
2 -14 -6 -39 -17 -57z"/>
<path d="M2150 2310 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M3026 2271 c-19 -60 -20 -72 -3 -95 15 -20 43 -13 58 15 8 16 6 19
-13 16 -22 -2 -23 1 -21 56 1 31 0 57 -2 57 -1 0 -10 -22 -19 -49z"/>
<path d="M2080 2301 c0 -4 11 -6 25 -3 14 2 25 6 25 8 0 2 -11 4 -25 4 -14 0
-25 -4 -25 -9z"/>
<path d="M2560 2283 c0 -10 -11 -41 -24 -70 -20 -41 -22 -53 -10 -57 9 -3 17
7 23 32 16 60 24 112 17 112 -3 0 -6 -8 -6 -17z"/>
<path d="M1506 2246 c-12 -79 10 -109 49 -66 21 23 13 39 -10 20 -16 -14 -19
-7 -15 37 1 12 -3 30 -8 39 -7 13 -11 6 -16 -30z"/>
<path d="M3130 2280 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M1608 2273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1870 2266 c0 -9 -9 -35 -20 -57 -19 -37 -20 -59 -1 -59 11 0 36 118
27 127 -3 3 -6 -1 -6 -11z"/>
<path d="M2223 2232 c21 -23 22 -36 2 -28 -8 3 -15 1 -15 -4 0 -6 11 -10 25
-10 30 0 33 20 7 43 -25 23 -39 22 -19 -1z"/>
<path d="M3161 2234 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3240 2238 c0 -7 -3 -30 -6 -50 -12 -69 25 -42 41 30 6 28 4 32 -14
32 -12 0 -21 -6 -21 -12z"/>
<path d="M3351 2204 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3146 2186 c-3 -13 -9 -31 -12 -40 -3 -9 -1 -16 4 -16 6 0 14 16 18
35 7 41 0 58 -10 21z"/>
<path d="M3380 2185 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M1623 2165 c-6 -14 -7 -25 -2 -25 11 0 22 25 17 39 -1 6 -8 -1 -15
-14z"/>
<path d="M1770 1939 c13 -43 20 -48 27 -19 l6 24 9 -23 c4 -12 12 -20 17 -17
12 7 20 66 9 66 -4 0 -8 -10 -9 -22 l0 -23 -11 23 -12 23 -12 -23 c-10 -17
-13 -19 -14 -6 0 9 -4 19 -9 23 -6 3 -6 -7 -1 -26z"/>
<path d="M1862 1935 c0 -22 3 -30 5 -17 2 12 8 22 13 22 5 0 11 -10 13 -22 4
-20 4 -19 6 5 1 17 -4 27 -13 27 -8 0 -17 6 -19 13 -3 6 -5 -6 -5 -28z"/>
<path d="M2130 1961 c0 -5 -9 -11 -21 -14 -28 -7 -21 -41 9 -45 19 -3 22 1 22
32 0 20 -2 36 -5 36 -3 0 -5 -4 -5 -9z m0 -36 c0 -8 -7 -15 -15 -15 -8 0 -15
7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z"/>
<path d="M2460 1934 c0 -31 3 -35 23 -32 31 4 38 48 7 48 -12 0 -18 5 -15 10
3 6 1 10 -4 10 -6 0 -11 -16 -11 -36z m40 -9 c0 -8 -4 -15 -10 -15 -5 0 -10 7
-10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z"/>
<path d="M2520 1941 c0 -17 4 -32 8 -35 4 -2 6 11 4 30 -5 42 -12 45 -12 5z"/>
<path d="M2740 1937 c0 -34 3 -38 22 -35 28 4 36 36 11 45 -10 4 -21 11 -25
17 -4 6 -8 -6 -8 -27z m38 -9 c-6 -18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14
-5 12 -12z"/>
<path d="M2212 1933 c2 -14 10 -29 18 -31 13 -5 13 -3 1 12 -12 14 -12 19 -2
25 10 6 9 10 -4 15 -13 5 -16 1 -13 -21z"/>
<path d="M3033 1946 c4 -10 7 -24 7 -32 0 -8 4 -14 8 -14 13 0 6 54 -8 58 -9
3 -11 -1 -7 -12z"/>
<path d="M1922 1928 c2 -14 11 -24 23 -25 l20 -3 -20 9 c-18 9 -18 10 -2 10
23 1 17 25 -7 29 -13 3 -17 -2 -14 -20z"/>
<path d="M1970 1925 c0 -14 5 -25 12 -25 6 0 8 3 5 6 -3 4 -2 15 4 25 7 15 6
19 -6 19 -9 0 -15 -9 -15 -25z"/>
<path d="M2018 1938 c-8 -20 2 -38 20 -37 11 0 12 2 5 6 -16 6 -17 33 -2 33 5
0 7 -4 4 -10 -3 -5 -1 -10 5 -10 17 0 6 24 -12 28 -9 2 -18 -3 -20 -10z"/>
<path d="M2168 1941 c11 -7 10 -9 -3 -15 -20 -7 -10 -26 14 -26 14 0 17 6 13
25 -2 15 -11 25 -21 25 -12 0 -13 -2 -3 -9z"/>
<path d="M2265 1941 c17 -8 18 -10 3 -10 -26 -1 -22 -31 3 -31 28 0 23 44 -6
47 l-20 3 20 -9z"/>
<path d="M2354 1935 c-8 -19 4 -35 24 -34 15 1 15 2 0 6 -10 2 -18 11 -18 18
0 7 8 16 18 18 16 4 16 5 -1 6 -10 0 -20 -6 -23 -14z"/>
<path d="M2420 1940 c10 -6 11 -10 3 -10 -7 0 -13 -7 -13 -15 0 -8 9 -15 20
-15 11 0 20 4 20 9 0 22 -13 41 -29 41 -14 0 -14 -2 -1 -10z"/>
<path d="M2554 1935 c-8 -19 3 -35 24 -34 14 0 13 2 -3 9 -19 8 -19 9 2 9 16
1 21 6 17 16 -8 19 -33 19 -40 0z"/>
<path d="M2635 1941 c-3 -5 -1 -12 5 -16 6 -4 8 -11 5 -16 -4 -5 -2 -9 4 -9
18 0 23 20 6 26 -13 6 -14 8 -3 15 9 6 9 9 1 9 -6 0 -14 -4 -18 -9z"/>
<path d="M2681 1928 c-1 -22 4 -28 19 -28 15 0 20 6 19 28 -2 23 -2 24 -6 5
-2 -13 -8 -23 -13 -23 -5 0 -11 10 -13 23 -4 19 -4 18 -6 -5z"/>
<path d="M2800 1935 c0 -8 6 -15 13 -15 8 0 7 -4 -3 -10 -13 -8 -12 -10 3 -10
9 0 17 7 17 15 0 8 -6 15 -12 15 -9 0 -8 4 2 10 13 8 12 10 -2 10 -10 0 -18
-7 -18 -15z"/>
<path d="M2854 1935 c-3 -8 -3 -19 1 -25 8 -13 35 -13 35 0 0 5 -7 7 -15 4 -9
-4 -15 0 -15 9 0 9 8 18 18 20 16 4 16 5 -1 6 -10 0 -20 -6 -23 -14z"/>
<path d="M2904 1935 c-10 -26 1 -35 13 -10 6 14 7 25 2 25 -5 0 -12 -7 -15
-15z"/>
<path d="M2942 1925 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2970 1913 c0 -21 4 -33 8 -27 4 6 15 13 25 17 25 9 17 41 -11 45
-19 3 -22 -1 -22 -35z m32 12 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5
15 11 13 6 -2 11 -8 11 -13z"/>
<path d="M3072 1925 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3104 1935 c-7 -18 3 -35 21 -35 15 0 24 19 18 38 -6 17 -32 15 -39
-3z m26 -10 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7
10 -15z"/>
<path d="M3161 1923 c2 -24 2 -25 6 -5 5 25 23 30 23 7 0 -8 4 -15 9 -15 5 0
7 9 4 20 -3 11 -14 20 -24 20 -14 0 -19 -7 -18 -27z"/>
<path d="M2820 1815 c0 -19 4 -35 8 -35 4 0 6 16 4 35 -2 19 -6 35 -8 35 -2 0
-4 -16 -4 -35z"/>
<path d="M2880 1840 c0 -5 -7 -10 -15 -10 -9 0 -15 -9 -15 -25 0 -18 5 -25 20
-25 16 0 20 7 20 35 0 19 -2 35 -5 35 -3 0 -5 -4 -5 -10z m0 -35 c0 -8 -4 -15
-10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z"/>
<path d="M2193 1832 c-18 -3 -23 -10 -22 -30 2 -24 2 -24 6 -4 5 27 33 29 33
2 0 -11 4 -20 8 -20 8 0 9 47 1 53 -2 1 -14 1 -26 -1z"/>
<path d="M2500 1811 c0 -23 5 -31 18 -31 14 0 14 2 3 9 -8 5 -11 16 -8 25 3 8
2 18 -3 21 -6 4 -10 -7 -10 -24z"/>
<path d="M2098 1821 c11 -7 11 -9 -2 -14 -23 -9 -20 -27 4 -27 15 0 20 7 20
25 0 17 -5 25 -17 25 -14 0 -15 -2 -5 -9z"/>
<path d="M2142 1805 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2252 1805 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2271 1803 l0 -28 11 25 c9 21 12 22 15 8 3 -10 8 -18 13 -18 5 0 10
8 13 18 3 14 6 13 15 -8 l12 -25 -3 25 c-2 21 -8 25 -39 27 -36 3 -38 2 -37
-24z"/>
<path d="M2364 1815 c-8 -19 3 -35 24 -34 14 0 13 2 -3 8 -19 8 -18 9 5 13
l25 5 -25 2 c-23 2 -24 3 -5 11 18 8 18 9 2 9 -10 1 -20 -6 -23 -14z"/>
<path d="M2447 1823 c-16 -16 -6 -43 16 -42 21 0 21 1 2 9 -17 7 -17 9 -2 9
20 1 24 31 4 31 -8 0 -17 -3 -20 -7z"/>
<path d="M2542 1808 c2 -12 9 -23 16 -24 7 -1 8 0 2 3 -14 7 -13 30 3 36 7 3
4 6 -6 6 -13 1 -18 -5 -15 -21z"/>
<path d="M2612 1805 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2640 1815 c0 -8 6 -15 13 -15 8 0 7 -4 -3 -10 -13 -8 -12 -10 3 -10
9 0 17 7 17 15 0 8 -6 15 -12 15 -9 0 -8 4 2 10 13 8 12 10 -2 10 -10 0 -18
-7 -18 -15z"/>
<path d="M2710 1815 c0 -8 6 -15 13 -15 8 0 7 -4 -3 -10 -13 -8 -13 -10 2 -10
23 0 33 19 13 26 -13 6 -14 8 -3 15 10 7 9 9 -4 9 -10 0 -18 -7 -18 -15z"/>
<path d="M2764 1815 c-7 -18 3 -35 21 -35 15 0 24 19 18 38 -6 17 -32 15 -39
-3z m26 -10 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7
10 -15z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
